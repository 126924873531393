/* eslint-disable jsx-a11y/anchor-is-valid */

import NoRecord from "../../../../_metronic/_partials/widgets/NoRecord";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
// import { divideByHundred } from "../../../utils/canvaCloseFunction";
import { useIntl } from "react-intl";
import {
  convertGBPtoPoundSymbol,
  // matchRemoveHash,
} from "../../../utils/commonFunction";
import packagingMaterialList from "../../../utils/packagingMaterial.json";
import { getMavisPackagingMaterial } from "../_redux/order/orderCrud";

export function OrderSubscriptionTable({ orderDetail }) {
  const intl = useIntl();
  const [listPackage, setListPackage] = useState([]);
  const [listMavisPackage, setListMavisPackage] = useState([]);
  const value = orderDetail?.subscription_data;

  const handlePackagingMaterial = () => {
    const matchingItems = [...listPackage];

    packagingMaterialList.forEach((orderItem) => {
      const packItem = orderDetail?.order_summary?.find(
        (packItem) => packItem?.id === orderItem.chargebee_charge_id
      );

      if (packItem) {
        matchingItems.push({
          id: packItem.id,
          name: packItem.name,
          price: packItem.price,
          quantity: packItem.quantity,
          item_price_id: packItem.item_price_id,
        });
      }
    });
    setListPackage(matchingItems);
  };
  const handleMavisPackaging = () => {
    getMavisPackagingMaterial({ order_id: orderDetail?.id }).then((res) => {
      setListMavisPackage(res.data.data);
    });
  };
  useEffect(() => {
    if (orderDetail?.id) {
      handleMavisPackaging();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetail?.id]);

  useEffect(() => {
    if (
      orderDetail?.order_summary &&
      orderDetail?.order_summary?.length > 0 &&
      !orderDetail?.packaging_materials
    ) {
      handlePackagingMaterial();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetail?.order_summary?.length]);
  const coverValue = (item) => {
    if (orderDetail?.order_summary?.length > 0) {
      const summaryItem = orderDetail.order_summary.find((e) => e.id === item?.product_id);
      
      if (orderDetail?.is_container == 1) {
        return Number(summaryItem?.insurance_cover) || Number(summaryItem?.cover)?.toLocaleString();
      } else {
        console.log("hello-90", summaryItem);
        const insurance = summaryItem?.cover ? Number(summaryItem?.cover)?.toLocaleString():Number(summaryItem?.insurance_cover)?.toLocaleString()
        return insurance
      }
    } 
    
    if (orderDetail?.insurance_cover !== null) {
      return Number(orderDetail?.insurance_cover).toLocaleString();
    }
    
    return "";
  };
  return (
    <div className={"customCheck"}>
      <Table responsive className="summerytable customColor">
        <thead>
          <tr>
            <th colSpan={2}>{intl.formatMessage({ id: "Product" })}</th>

            <th style={{ textAlign: "right" }}>
              {/* {intl.formatMessage({ id: "Total" })} */}
            </th>
            <th style={{ textAlign: "right" }}>
              {intl.formatMessage({ id: "Qty" })}
            </th>
          </tr>
        </thead>
        <tbody>
          {value?.subscription_items ? (
            value?.subscription_items?.map((item, index) => (
              <tr key={index}>
                {/* {type !== "last" ? <td>{item?.product_id || "-"}</td> : ""} */}
                <td colSpan={2}>
                  {item?.external_name || "-"}{" "}
                  {/* {item?.item_type === "plan"
                    ? `(${item?.quantity})`
                    : item?.item_type === "addon"
                    ? orderDetail?.insurance_cover
                    : ""} */}
                </td>

                <td className="d-flex">
                  {/* <span style={{ width: "60%", textAlign: "right" }}>
                    {orderDetail?.currency
                      ? convertGBPtoPoundSymbol(orderDetail?.currency)
                      : "-"}
                  </span>

                  <span style={{ width: "40%", textAlign: "right" }}>
                    {" "}
                    {item?.amount === 0
                      ? 0
                      : divideByHundred(item?.amount || null)}
                  </span> */}
                </td>
                <td style={{ textAlign: "right" }}>
                  {(item?.item_type === "addon" &&
                    item?.product_id === "Insurance-Cover") || (item?.item_type === "addon" && item?.product_id === "Store-Protect")
                    ? orderDetail?.currency
                      ? convertGBPtoPoundSymbol(orderDetail?.currency)
                      : "-"
                    : ""}
                  {item?.item_type === "plan"
                    ? `${item?.quantity}`
                    : (item?.item_type === "addon" &&
                      item?.product_id === "Insurance-Cover") || (item?.item_type === "addon" && item?.product_id === "Store-Protect")
                      ? " " + coverValue(item) 
                                            : item?.quantity
                        ? item?.quantity
                        : ""}
                </td>
              </tr>
            ))
          ) : orderDetail?.packaging_materials &&
            orderDetail?.packaging_materials?.length > 0 ? (
            ""
          ) : (
            <tr>
              <td colSpan={"5"}>
                <>
                  <NoRecord />
                </>
                {/* )} */}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {(orderDetail?.packaging_materials &&
        orderDetail?.packaging_materials?.length > 0) ||
        (listPackage && listPackage?.length > 0) ||
        (listMavisPackage && listMavisPackage?.length > 0) ? (
        <>
          <h5 className="card-title align-items-start flex-column">
            <span className="font-weight-bolder text-dark">
              Packaging Material{" "}
              <span style={{ fontSize: "14px" }}>

                {orderDetail?.is_ship_materials === 1
                  ? "(shipped)"
                  : "(not shipped)"}
              </span>
            </span>
          </h5>
          <Table responsive className="summerytable customColor">
            <thead>
              <tr>
                <th colSpan={2}>{intl.formatMessage({ id: "Product" })}</th>

                <th style={{ textAlign: "right" }}>
                  {/* {intl.formatMessage({ id: "Total" })} */}
                </th>
                <th style={{ textAlign: "right" }}>
                  {intl.formatMessage({ id: "Qty" })}
                </th>
              </tr>
            </thead>
            <tbody>
              {orderDetail?.packaging_materials?.length > 0 ? (
                orderDetail.packaging_materials.map((item, index) => (
                  <tr key={index}>
                    <td colSpan={2}>{item?.id || "-"}</td>
                    <td className="d-flex"></td>
                    <td style={{ textAlign: "right" }}>
                      {item?.quantity || ""}
                    </td>
                  </tr>
                ))
              ) : listPackage?.length > 0 ? (
                listPackage.map((item, index) => (
                  <tr key={index}>
                    <td colSpan={2}>{item?.id || "-"}</td>
                    <td className="d-flex"></td>
                    <td style={{ textAlign: "right" }}>
                      {item?.quantity || ""}
                    </td>
                  </tr>
                ))
              ) : listMavisPackage && listMavisPackage.length > 0 ? (
                ""
              ) : (
                <tr>
                  <td colSpan={5}>
                    <NoRecord />
                  </td>
                </tr>
              )}
              {listMavisPackage && listMavisPackage.length > 0
                ? listMavisPackage.map((item, index) => (
                  <tr key={index}>
                    <td colSpan={2}>{item?.id || "-"}</td>
                    <td className="d-flex"></td>
                    <td style={{ textAlign: "right" }}>{item?.qty || ""}</td>
                  </tr>
                ))
                : ""}
            </tbody>
          </Table>
        </>
      ) : (
        ""
      )}
    </div>
  );
}
