import React, { useState, useEffect, useMemo } from "react";
import CompetitorPriceModal from "../../order-modal/CompetitorPriceModal";
// import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
// import SVG from "react-inlinesvg";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { fetchOrderSummary } from "../../_redux/order/orderAction";
import { convertGBPtoPoundSymbol } from "../../../../utils/commonFunction";

export const CompetitorPriceCheckBox = ({
  dispatchReducer,
  state,
  paramData,
  handleBilling,
}) => {
  const [dialog, setDialog] = useState({});
  const dispatch = useDispatch();
  const { cardDetail } = useSelector(
    (state) => ({
      cardDetail: state.order.cardDetail,
    }),
    shallowEqual
  );
  
  const get25Plan = useMemo(() => {
    const plan = state.productArray?.find((e)=>e.type === "plan")
    const addon = state.productArray?.find((e)=>e.type === "addon")
    const addonPrice = addon?.total ? Number(addon?.total) : 0
    const price = plan?.cost ? Number(plan?.cost):0

const discountPrice25 = price*0.25

const invoice = (price - discountPrice25) + addonPrice

const digit2 = +invoice.toFixed(2)
return digit2
  }, [state.productArray,state.productArray?.length])
  const get100Plan = useMemo(() => {
    const plan = state.productArray?.find((e)=>e.type === "plan")
    const addon = state.productArray?.find((e)=>e.type === "addon")
    const addonPrice = addon?.total ? Number(addon?.total) : 0
    const price = plan?.cost ? Number(plan?.cost):0

const discountPrice25 = price

const invoice = Number(discountPrice25) + Number(addonPrice)

const digit2 = +invoice.toFixed(2)
return digit2
  }, [state.productArray,state.productArray?.length])
  const handleCheck = (e, values, dataPriceModal) => {
    const dataPrice = {
      price: values || 0,
      check: e === "check" ? true : false,
      name: e === "check" ? dataPriceModal?.name : "",
    };
    dispatchReducer({ type: "competitorPrice", value: dataPrice });

    const data = {
      collection_date: state?.collectionRetrun?.collection_date || "",
      pod:
        (state?.productArray.length > 0 &&
          state?.productArray.find((e) => e.type === "plan")?.qty) ||
        "",
      return_date:
        state.collectionRetrun?.return_date &&
        state.collectionRetrun?.return_start_time_slot
          ? state.collectionRetrun?.return_date
          : "",
      return_postcode:
        state.collectionRetrun?.return_postcode &&
        state.collectionRetrun?.return_start_time_slot
          ? state.collectionRetrun?.return_postcode
          : "",
      is_student: state.collectionRetrun?.is_student || 0,
      is_student_return: state.collectionRetrun?.is_student_return || 0,
      competitor_price: e === "check" ? values : 0,
      isCompetitor: e === "check",
    };
    if (e === "check") {
      dispatchReducer({ type: "coupon", value: [] });
      dispatch(
        fetchOrderSummary(
          state?.deletedProduct,
          "12",
          paramData.unit,
          paramData.period,
          paramData?.products_id,
          cardDetail && cardDetail.length && cardDetail[0].contact?.franchise_id
            ? cardDetail[0].contact?.franchise_id
            : "",
          cardDetail && cardDetail.length && cardDetail[0].postcode
            ? cardDetail[0].postcode
            : "",
          "",
          "",
          "",
          "",
          data
        )
      );
    } else {
      handleBilling(
        state?.params?.period === "week"
          ? "1w"
          : state?.params?.unit
          ? state?.params?.unit
          : 1,
        data
      );
    }
  };
  useEffect(() => {
    if (state.competitorPrice?.price && state.productArray.length) {
      const pod = state.productArray.find((e) => e.type === "plan");
      const competitorManualDis = pod?.competitor_manual_discount;
      const manualDiscount = {
        applyOn: "specific_item_price",
        durationType: "limited_period",
        amount: competitorManualDis,
        manual_discount: pod?.manual_discount,
        period: "2",
        periodUnit: "month",
        manual_discount_item_price_id: pod?.price_id,
        discount_type: "fixed_amount",
      };
      dispatchReducer({
        type: "manualDiscount",
        manualDiscount: manualDiscount,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.competitorPrice?.price, state.productArray.length]);

  const result = useMemo(() => {
    // Find the plan and addon products from the productArray
    const planProduct = state?.productArray.find((e) => e.type === "plan");
    const addonProduct = state?.productArray.find((e) => e.type === "addon");

    // Calculate the values for pod_25 and total, and ensure they are numbers with 2 decimal places
    const pod25Value =
      Number(planProduct?.pod_25 ? planProduct?.pod_25?.toFixed(2) : 0) *
      Number(planProduct?.qty);
    const addonTotal = Number(addonProduct?.total)
      ? Number(parseFloat(addonProduct?.total).toFixed(2))
      : 0 || 0;

    // Calculate the final result
    const price = pod25Value + addonTotal;
    return price;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.productArray]);
  return (
    <>
      <tr>
        <td colSpan="2" className="bord-top">
          <div className="d-flex">
            <label className="checkbox">
              {/* {state.competitorPrice?.price && ( */}
              <>
                <input
                  type="checkbox"
                  name="enabled_for_checkout"
                  checked={state.competitorPrice?.check}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setDialog({ check: true });
                    } else {
                      handleCheck("checked");
                    }
                  }}
                  disabled={state.productArray.some((e) => e.id === "Howdens")}
                />
                <span className="mr-2"></span>
              </>
              {/* )} */}
              Competitor&nbsp;Price&nbsp;
              {state?.competitorPrice?.price && state?.competitorPrice?.check
                ? state?.currencyCode
                  ? convertGBPtoPoundSymbol(state.currencyCode) +
                    state?.competitorPrice?.price
                  : ""
                : ""}
            </label>

            {/* <div className="edit-icon">
              <span className="symbol-label ml-2">
                <span
                  className="svg-icon svg-icon-sm svg-icon-primary "
                  role="button"
                  onClick={() => {
                    setDialog({ check: true });
                  }}
                >
                  <SVG
                    title=" "
                    className="h-25 align-self-end"
                    src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")}
                  ></SVG>
                </span>
              </span>
            </div> */}
          </div>

          {state?.productArray.length > 0 && state?.competitorPrice?.check && (
            <>
              <span style={{ fontSize: "11px" }}>
                Price After{" "}
                {state?.params?.unit && Number(state?.params?.unit) > 2
                  ? state?.params?.unit
                  : "2"}{" "}
                Months:-{" "}
                {state?.currencyCode
                  ? convertGBPtoPoundSymbol(state.currencyCode)
                  : ""}
                {result?.toFixed(2) || 0}{" "}
              </span>
            </>
          )}
        </td>
      </tr>
      <tr>
        <td colSpan="2" className="bord-top">
          <div className="d-flex">
            {cardDetail && cardDetail?.length >0 && cardDetail && cardDetail.length && cardDetail[0].contact?.franchise_id != 18 ?<>
{cardDetail && cardDetail?.length >0 && cardDetail[0]?.contact?.franchise?.discount_after_renewal ==1 ? `25% discount after ${+state.params?.unit>3?state.params?.unit:"3"} months ` :"25% discount not applicable"}
            </>:""}

            
          </div>
<div>
Price after 3 months: &pound; {cardDetail && cardDetail?.length >0 && cardDetail && cardDetail.length && cardDetail[0].contact?.franchise_id == 18?get100Plan:get25Plan}
</div>
        
        </td>
      </tr>
      <CompetitorPriceModal
        dialog={dialog}
        setDialog={setDialog}
        dispatchReducer={dispatchReducer}
        state={state}
        paramData={paramData}
        handleCheck={handleCheck}
      />
    </>
  );
};
